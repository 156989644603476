import { getContentType } from "@src/app/utils/fileUpload";
import { environmentConfig } from "@src/appV2/environment";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { ErrorMessages } from "@src/constants/strings";
import { logApiFailureEvent } from "@src/lib/analytics";
import { FssUploadResponse } from "@src/types/Fss/index.types";
import request from "superagent";

import { getAuthHeader } from "../../../superagent";

const uploadProfileImage = async ({ croppedImageFile, userId }): Promise<string | undefined> => {
  if (!userId) {
    deprecatedDoNotUseLogError({
      message: ErrorMessages.PROFILE_IMAGE_FAILED_NO_USER_ID_SUPPLIED.message,
    });
    return;
  }
  try {
    const { fileStorageFileKey } = (await uploadProfilePicToFss(
      userId,
      croppedImageFile,
      "jpeg"
    )) || { fileStorageFileKey: "" };
    await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/profilePic`)
      .set(await getAuthHeader())
      .send({
        profileImagefileStorageFileKey: fileStorageFileKey,
      });
    return fileStorageFileKey;
  } catch (error) {
    logApiFailureEvent(error);
  }
};
/**
 * @description use this util to Fetch PresignedURL and Upload the file to FSS
 */
const uploadProfilePicToFss = async (
  workerId: string,
  file: File,
  fileExtension: string
): Promise<FssUploadResponse | undefined> => {
  const contentType = getContentType[fileExtension.toLowerCase()];
  if (!contentType) {
    deprecatedDoNotUseLogError({
      message: ErrorMessages.FILE_EXTENSION_INVALID.message,
    });
    return;
  }
  const preSignedURLResponse = await request
    .get(
      `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/${workerId}/profile-image-presigned-upload-url`
    )
    .set(await getAuthHeader())
    .query({
      extension: fileExtension,
    });
  const { presigned_url, fileStorageFileKey } = preSignedURLResponse.body;
  await request.put(presigned_url).set("Content-Type", contentType).send(file);
  return { fileStorageFileKey };
};

export { uploadProfileImage };
