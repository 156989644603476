import { isDefined } from "@clipboard-health/util-ts";
import { PercentCrop } from "react-image-crop";

export function getPercentCropRatio(percentCrop: PercentCrop) {
  return {
    x: percentCrop.x / 100,
    y: percentCrop.y / 100,
    width: percentCrop.width / 100,
    height: percentCrop.height / 100,
  };
}

export function getCroppedImage(image: HTMLImageElement, percentCrop: PercentCrop): Promise<Blob> {
  if (percentCrop.unit !== "%") {
    throw new Error("You must always use percentage crop");
  }

  const percentCropRatio = getPercentCropRatio(percentCrop);
  const canvas = document.createElement("canvas");
  const { naturalWidth, naturalHeight } = image;
  canvas.width = percentCropRatio.width * image.width;
  canvas.height = percentCropRatio.height * image.height;
  const canvasContext = canvas.getContext("2d") as CanvasRenderingContext2D;

  canvasContext.drawImage(
    image,
    percentCropRatio.x * naturalWidth,
    percentCropRatio.y * naturalHeight,
    percentCropRatio.width * naturalWidth,
    percentCropRatio.height * naturalHeight,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!isDefined(blob)) {
        /**
         * FIXME:
         * This was original functionality, but perhaps we should throw or reject
         */
        console.error("Canvas is empty");
        return;
      }
      resolve(blob);
    }, "image/jpeg");
  });
}
